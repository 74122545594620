<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos del cliente
      </template>
      <v-expansion-panel-content>
        <ViewClienteSolcitudInfo v-if="switch_data === 1" :datosSolicitud="datosSolicitud"
          @changeComponent="switch_data = 2" :rol="rol" />
        <EditClienteSolcitudInfo v-else-if="canEdit" :datosSolicitud="datosSolicitud" :needCreateCliente="needCreateCliente" @changeComponent="switch_data = 1" 
          @getData="$emit('getData'), switch_data = 1" />
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewClienteSolcitudInfo from "./ViewClienteSolcitudInfo.vue";
import EditClienteSolcitudInfo from "./EditClienteSolcitudInfo.vue";


export default {
  components: {
    CommonExpansionPanel,
    ViewClienteSolcitudInfo,
    EditClienteSolcitudInfo,
  },
  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    datosSolicitud: Object,
    rol: String,
    needCreateCliente: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canEdit() {
      return this.rol !== 'ADMINAGENT';
  },
  watch:{
    cliente_id(){
      this.clienteId()
    }
  },
  methods: {
    reaload(){
      this.switch_data = 1
      location.reload()
    },
    async clienteId() {
    },
  },
}
};

</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}

.v-expansion-panels {
  z-index: auto !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
