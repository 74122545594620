<template>
  <div>  
    <v-main> 
      <div>
        <v-row class="mt-6">
          <v-col cols="12" class="d-flex justify-center align-md-center">
            <v-img
              class="responsive mx-5"
              contain
              max-height="150"
              max-width="300"
            :src="LogoInter"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="9">
            <h2>
              <iframe
              :src="jsonInfo.serviceUrl"
                style="width: 100%; min-height: 100vh;"
                frameborder="0"
              ></iframe>
            </h2>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <DetallesPago></DetallesPago>
          </v-col>
        </v-row>
      </div>
    </v-main>
    <v-footer app> 
      <v-col class="text-center">
        <p class="subtitulo-header-2 ma-0">Plan  {{getTipoPlanName(jsonInfo.cotizacionSelected.plan)}}</p><br>
        <p class="font-montserrat">{{toCurrency(jsonInfo.cotizacionSelected.price) }} /Anual</p>
      </v-col>
    </v-footer>
  </div>
</template>


<script>
import LogoInter from "@/assets/interify.svg";
import DetallesPago from "./DetallesPago.vue";

export default {
  components:{
    DetallesPago
  },
  data() {
    return {
      LogoInter: LogoInter,
      tiposPlan: [
        {
          id: "AMP",
          nombre: "AMPLIA",
        },
        {
          id: "RC",
          nombre: "RESPONSABILIDAD CIVIL",
        },
        {
          id: "LIMIT",
          nombre: "LIMITADA",
        },
      ],
    };
  },
  computed: {
    jsonInfo() {
      return this.$route.query.jsonInfo
        ? JSON.parse(this.$route.query.jsonInfo)
        : null;
    },
  },
  methods:{
    getTipoPlanName(id){
      return this.tiposPlan.find(
        (tipoPlan) => tipoPlan.id == id
      ).nombre;
    },
    toCurrency(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number); /* $2,500.00 */
    },
  }


};
</script>
