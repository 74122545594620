<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="4" xl="4">
        <common-card-docs
          :docs="infoCards.docs"
          :routesConfigDocsCard="routesConfigNoteCard"
          :openAll="openAll"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" xl="4">
        <common-card-notes
          :notas="infoCards.notas"
          :routesConfigNoteCard="routesConfigNoteCard"
          :openAll="openAll"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4">
        <common-card-email
          :emails="infoCards.emails"
          :correosSegurify="infoCards.correosSegurify"
          :emailRoutesConfig="emailRoutesConfig"
          :openAll="openAll"
          @getData="getData"
          @showAll="showAll"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonCardDocs from "@/components/commonComponents/cards/CommonCardDocs.vue";
import CommonCardEmail from "@/components/commonComponents/cards/CommonCardEmail.vue";
import CommonCardNotes from "@/components/commonComponents/cards/CommonCardNotes.vue";

export default {
  components: {
    CommonCardDocs,
    CommonCardEmail,
    CommonCardNotes,
  },
  props:{
    infoCards: Object,
    emailRoutesConfig: Object,
  },
  data() {
    return {
      routesConfigNoteCard: {
        route:`/v1/solicitud/documento/upload/${this.$route.params.id}`,
        action: 'POST'
      },
      switch_data: 1,
      openAll: false
    }
  },
  methods: {
      getData(){
        this.$emit("getData")
      },
      showAll(val){
        this.openAll = val
      }
    },
};
</script>
|
