// pixel-scripts.js
export function loadGTM() {
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WN2RX2VZ');
}

export function loadFacebookPixel() {
!function(f,b,e,v,n,t,s) {
    if (f.fbq) return; n = f.fbq = function() {
      n.callMethod? n.callMethod.apply(n, arguments): n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n; n.push = n; n.loaded =!0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async =!0;
    t.src = v; s = b.getElementsByTagName(e);
    s[0].parentNode.insertBefore(t, s[0])
  }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  const checkFbq = setInterval(() => {
    let fbq = {}.hasOwnProperty.call(window, 'fbq');
    if (typeof fbq === 'function') {
      fbq('init', '646099537805184');
      fbq('track', 'PageView');
      clearInterval(checkFbq);
    }
  }, 100);

  // No-script fallback
  let noscript = document.createElement('noscript');
  let img = document.createElement('img');
  img.height = "1";
  img.width = "1";
  img.style.display = "none";
  img.src = "https://www.facebook.com/tr?id=646099537805184&ev=PageView&noscript=1";
  noscript.appendChild(img);
  document.head.appendChild(noscript);
}
