<template>
  <div>
  </div>
</template>
<script>
import { loadGTM, loadFacebookPixel } from './pixel-scripts.js';

export default {
  mounted() {
    loadGTM();
    loadFacebookPixel();
  }
}
</script>