<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel> Asignación y seguimiento </template>
      <v-expansion-panel-content>
        <UpdateSeguimientoSolicitudForm v-if="showEditForm" :solicitud="datosSolicitud" @getData="$emit('getData')"
          @cancel="showEditForm = false"></UpdateSeguimientoSolicitudForm>
        <div v-else>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn rounded dark class="common-botton" @click="showEditForm = true" v-if="canEdit">
                <span style="padding-right: 11%;">
                  Editar
                </span>
                <v-icon rigth>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-col>
            <v-col ccols="12" sm="6" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Ejecutivo
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.nombreEjecutivo
                }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="
              datosSolicitud.tipoSelected == 'Cotización' ||
              datosSolicitud.tipoSelected == 'Emisión' ||
              datosSolicitud.tipoSelected == 'Renovación'
            ">
              <span class="text-header-data ">
                Proceso de Cotización
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.procesoCotizacion
                    ? datosSolicitud.procesoCotizacion
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                ¿Solicitud Completa?
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.solicitudCompletaSelected
                    ? datosSolicitud.solicitudCompletaSelected
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="datosSolicitud.valorOportunidad">
              <span class="text-header-data ">
                Valor Oportunidad
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.valorOportunidad
                    ? datosSolicitud.valorOportunidad
                    : "Sin llenar"
                }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Fecha de Creacion
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.fechaCreacion
                    ? datosSolicitud.fechaCreacion
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="text-header-data ">
                Creado por
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.creadoPor
                    ? datosSolicitud.creadoPor
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Fecha de modificacion
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.fechaModificacion
                    ? datosSolicitud.fechaModificacion
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Modificado por
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.modificadoPor
                    ? datosSolicitud.modificadoPor
                    : "Sin llenar"
                }}
              </span>
            </v-col>
          </v-row>
        </div>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
    UpdateSeguimientoSolicitudForm: () =>
      import(
        "../Update/CreateSolicitudData/UpdateSeguimientoSolicitudForm.vue"
      ),
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      showEditForm: false,
      ejecutivos: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  props: {
    datosSolicitud: Object,
    operadores: {
      type:Array,
      default:()=>[]
    },
  },
  computed: {
    canEdit() {
      switch (this.rol) {
        case 'AGENT':
          if (this.datosSolicitud.status == 'Pendiente de Aprobacion')
            return true
          else
            return false
        case 'ADMINAGENT':
        case 'AGENTCORP':
          return false
        default:
          return true
      }
    }
  },
  mounted() {
    this.obtenerEjecutivos()
  },
  methods: {
    getAgentName(id) {
      return this.ejecutivos.find((e) => e.id == id) ? this.ejecutivos.find((e) => e.id == id).nombre : 'Sin asignar'
    },
    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agentes/operador", config).then((response) => {
        response.data.map((element) => {
          this.ejecutivos.push({
            nombre:
              `${element.nombre}` +
              " " +
              `${element.apellidoPaterno}` +
              " " +
              `${element.apellidoMaterno ? element.apellidoMaterno : ""}`,
            id: element.id,
            uuid: element.uuid,
          });
          if (this.rol == "OPERADOR") {
            localStorage.agenteUuid == element.uuid
              ? (this.form.ejecutivo = element.uuid)
              : "";
          }
        });

        const currentOperador = this.ejecutivos.find(
          (e) => e.id == this.datosSolicitud.ejecutivo
        );
        if (!currentOperador)
          this.ejecutivos = [
            {
              nombre: this.datosSolicitud.nombreEjecutivo,
              id: this.datosSolicitud.ejecutivo,
            },
            ...this.ejecutivos,
          ];
      });
    },
  },
};
</script>
