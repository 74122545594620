<template>
  <div>
    <v-expansion-panels elevation="0" v-model="panel">
      <v-expansion-panel elevation="0" style="padding-bottom: 20px;">
        <v-expansion-panel-header style="padding: 35px">
          <h3 style="color: #42352e">Búsqueda detallada</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="areaNegocioId"
                  label="Tipo de solicitud"
                  placeholder="Requerido"
                  color="#00a7e4"
                  :items="tipoSolicitud"
                  item-value="areaNegocioId"
                  item-text="name"
                  type="text"
                  :light="true"
                  id="areaNegocioId"
                  dense
                  background-color="white"
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="areaNegocioId != null">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.id"
                  label="No. Solicitud"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="form.empresa"
                  label="Empresa"
                  placeholder="Requerido"
                  color="#00a7e4"
                  item-value="empresa"
                  item-text="empresa"
                  :items="currentEmpresas"
                  type="text"
                  :light="true"
                  :loading="loadingForm"
                  id="empresa"
                  dense
                  background-color="white"
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="menuFecha"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="datesSelectedString"
                      label="Rango de creación"
                      outlined
                      dense
                      v-on="on"
                      v-bind="attrs"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      :light="true"
                      background-color="white"
                      class="share-bar-clase"
                    ></v-text-field>
                  </template>
                  <v-card class="pa-0">
                    <v-date-picker
                      v-model="form.fecha_creacion"
                      range
                      locale="es"
                      selected-items-text="2 seleccionadas"
                    ></v-date-picker>
                    <v-card-actions>
                      <v-btn small text @click="form.fecha_creacion = []"
                        >Limpiar</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="menuFecha = false"
                        >Listo</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.apelido_paterno"
                  label="Apellido Paterno"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.apellido_materno"
                  label="Apellido Materno"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.rfc"
                  label="RFC"
                  outlined
                  dense
                  :rules="rfcRules"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.celular"
                  label="Teléfono"
                  outlined
                  dense
                  prepend-inner-icon="mdi-phone"
                  maxLength="10"
                  counter
                  :rules="[$rules.numeric, localRules.longDigit(10)]"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.correo_electronico"
                  label="Correo electrónico"
                  outlined
                  dense
                  prepend-inner-icon="mdi-email"
                  :rules="[$rules.email]"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="pt-5" v-if="areaNegocioId != null">
      <v-row>
        <v-col cols="6" md="2">
          <v-select
            v-model="form.status"
            label="Estatus"
            :items="solicitudesStatus"
            item-text="title"
            item-value="id"
            outlined
            dense
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            v-model="form.tipificacion"
            label="Tipificacion"
            :items="currentTipificaciones"
            item-text="tipificacion"
            item-value="tipificacion"
            :loading="loadingForm"
            outlined
            dense
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6" md="2" v-if="rol != 'BACKOFFICEEMAILS' && this.rol != 'BACKOFFICEINBOUND' ">
          <v-select
            v-model="form.operador_id"
            label="Ejecutivo"
            :items="usuarios"
            item-text="nombreCompleto"
            item-value="id"
            outlined
            dense
            clearable
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <!-- <v-select
            v-model="form.ramo"
            label="Ramo"
            :items="ramos"
            item-text="ramo"
            item-value="ramo"
            outlined
            dense
            clearable
          ></v-select> -->
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            class="common-botton"
            dark
            block
            :disabled="!isFormValid"
            :loading="loading"
            @click="updateSearch"
          >
            Filtrar
          </v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            dark
            block
            outlined
            color="#00a7e4"
            rounded
            @click="clearForm()"
          >
            Limpiar
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getCampaniasEmailsList } from "@/services/campañasEmails/campañasEmails.js";
import { getRamoList } from "@/services/ramo/ramo.service.js";
// import { getUsersList } from "@/services/user/user.service";
import { getCampañasInbound } from "@/services/campañasInbound/CampañasInbound.js";
import { getAgentesByFilters } from "@/services/user/user.service";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        id: "",
        fecha_creacion: [],
        nombre: "",
        apelido_paterno: "",
        apellido_materno: "",
        celular: "",
        status: "",
        rfc: "",
        correo_electronico: "",
        empresa: "",
        tipificacion: "",
        operador_id: "",
      },
      menuFecha: false,
      solicitudesStatus: [
        {
          id: 0,
          title: "Nuevas",
        },
        {
          id: 1,
          title: "Asignada",
        },
        {
          id: 2,
          title: "En tramite",
        },
        {
          id: 3,
          title: "Pendiente de Aprobación",
        },
        {
          id: 4,
          title: "Pendiente de Información",
        },
        {
          id: 5,
          title: "Recotizar",
        },
        {
          id: 6,
          title: "Cerradas", //no esta api
        },
        {
          id: 7,
          title: "Canceladas",
        },
      ],
      agentes: [],
      operadores: [],
      asesores: [],
      ramos: [],
      localRules: {
        longDigit(number) {
          return (v) =>
            !v ||
            v.length == number ||
            "Debe proporcionar " + number + " digitos";
        },
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
      ],
      campaniasEmails: [],
      currentEmpresas: [],
      currentTipificaciones: [],
      total: 0,
      usuarios: [],
      tipoSolicitud: [
        {
          areaNegocioId: "3",
          name: "Llamada inbound",
        },
        {
          areaNegocioId: "6",
          name: "Buzon de correo",
        },
      ],
      areaNegocioId: null,
      loadingForm: false,
      panel:0
    };
  },

  computed: {
    isFormValid() {
      const isValidValue = (value) =>
        value !== "" && value !== null && value !== undefined;
      const hasNonEmptyField = Object.keys(this.form)
        .filter((key) => key !== "fecha_creacion")
        .some((key) => isValidValue(this.form[key]));

      const fechaCreacionValid =
        Array.isArray(this.form.fecha_creacion) &&
        this.form.fecha_creacion.length === 2 &&
        this.form.fecha_creacion.every(
          (date) => isValidValue(date) && this.isDate(date)
        );

      return hasNonEmptyField || fechaCreacionValid;
    },
    datesSelectedString() {
      if (this.form.fecha_creacion.length > 1)
        return `${this.formatDate(
          this.form.fecha_creacion[0]
        )} a ${this.formatDate(this.form.fecha_creacion[1])}`;
      return "";
    },
    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },
    // currentOperadores() {
    //   return this.usuarios.filter((e) => e.rol == "BACKOFFICEEMAILS");
    // },
  },

  mounted() {
    this.getRamoList();
    this.getAgentesByFilters();
    //this.getUsersList();
    //this.getCampaniasEmailsList();
    if (this.rol == "BACKOFFICEEMAILS" || this.rol == "BACKOFFICEINBOUND")
      this.form.operador_id = localStorage.id;
  },

  methods: {
    // async getUsersList() {
    //   this.usuarios = await getUsersList();
    // },
    async getRamoList() {
      this.ramos = await getRamoList();
    },
    async getCampaniasEmailsList() {
      this.campaniasEmails = await getCampaniasEmailsList();
      this.calculateEmpresas();
      this.calculateTipificaciones();
      this.loadingForm = false;
    },
    updateSearch() {
      if (!this.isFormValid || !this.minimoUnCampoLleno) return;
      let payload = {};

      Object.keys(this.form).forEach((key) => {
        const value = this.form[key];
        if (
          value !== null &&
          value !== undefined &&
          !(Array.isArray(value) && value.length === 0) &&
          (value || value === 0)
        ) {
          if (
            key === "fecha_creacion" &&
            Array.isArray(value) &&
            value.length === 2
          ) {
            const [inicio, fin] = value;
            payload[key] = [
              `${this.formatDateToStartOfDay(inicio)}`,
              `${this.formatDateToEndOfDay(fin)}`,
            ];
          } else {
            payload[key] = value;
          }
        }
      });
      this.$emit("getAreaNegocioFilters", payload, this.areaNegocioId);
    },
    calculateEmpresas() {
      let filteredCampaigns = this.campaniasEmails;
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.empresa))
      ).map((empresa) => {
        return filteredCampaigns.find((a) => a.empresa === empresa);
      });
      this.currentEmpresas = uniqueProducts;
    },
    calculateTipificaciones() {
      let uniqueTipificaciones = Array.from(
        new Set(this.campaniasEmails.map((a) => a.tipificacion))
      ).map((tipificacion) => {
        return this.campaniasEmails.find(
          (a) => a.tipificacion === tipificacion
        );
      });
      this.currentTipificaciones = uniqueTipificaciones;
    },
    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
      if (this.rol == "BACKOFFICEEMAILS")
        this.form.operador_id = localStorage.id;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    isDate(value) {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      return dateRegex.test(value) && !isNaN(Date.parse(value));
    },
    formatDateToStartOfDay(date) {
      return `${date} 00:00:00`;
    },
    formatDateToEndOfDay(date) {
      return `${date} 23:59:59`;
    },
    async getCampañasInbound() {
      this.campaniasEmails  = await getCampañasInbound();
      this.calculateEmpresas();
      this.calculateTipificaciones();
      this.loadingForm = false;
    },
    async getAgentesByFilters(limit = 10, page = 1) {
      let filters = {rol:['BACKOFFICEINBOUND', 'BACKOFFICEEMAILS']}
      let response = await getAgentesByFilters(filters, limit, page)  
      this.usuarios = response.data ?? []
    },
  },
  watch: {
    areaNegocioId(val) {
      this.loadingForm = true;
      if (val == 6) this.getCampaniasEmailsList();
      if (val == 3) this.getCampañasInbound();
    },
  },
};
</script>
<style scoped>
.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
  background-color: #f7f8fa;
}
.v-expansion-panel-header {
  background-color: #f7f8fa;
  border-radius: 20px !important;
}
</style>
