<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
        >
          <span style="padding-right: 11%;">Editar</span>
          <v-icon right>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Estatus</span>
        <br />
        <span class="text-data-details">
          {{ formData.status ? formData.status : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Medio de contacto</span>
        <br />
        <span class="text-data-details">
          {{ formData.tipo_llamada ? formData.tipo_llamada : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Producto</span>
        <br />
        <span class="text-data-details">
          {{ formData.producto ? formData.producto : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Empresa</span>
        <br />
        <span class="text-data-details">
          {{ formData.empresa ? formData.empresa : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Aseguradora</span>
        <br />
        <span class="text-data-details">
          {{ formData.aseguradora ? getCurrentAseguradora(formData.aseguradora) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Tipificación</span>
        <br />
        <span class="text-data-details">
          {{ formData.tipificacion ? formData.tipificacion : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Motivo</span>
        <br />
        <span class="text-data-details">
          {{ formData.motivo ? formData.motivo : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data">Póliza</span>
        <br />
        <span class="text-data-details">
          {{ formData.poliza ? formData.poliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="3" md="3" lg="3" xl="3">
        <span class="text-header-data whitout-padding-bottom ">
          ¿Es queja?
        </span>
        <v-radio-group v-model="formData.isQueja" row :disabled="true">
          <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
          <v-radio label="No" value="false" color="#00a7e4"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <span class="text-header-data">Documentos solicitados</span>
        <br />
        <span class="text-data-details">
          {{ formData.documentos_solicitados ? formData.documentos_solicitados : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    datosSolicitud: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        tipo_llamada: "Correo Electronico",
        producto: null,
        empresa: null,
        aseguradora: null,
        tipificacion: null,
        motivo: null,
        plantilla: null,
        documentos_solicitados: null,
        isQueja:null,
        poliza:null,
        agente_id:null,
        status:null
      },
      aseguradorasList: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA Seguros" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero Seguros" },
        { id: 5, name: "Quálitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "SURA RSA Seguros" },
        { id: 8, name: "AIG Seguros México" },
        { id: 9, name: "HDI Seguros" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "BANORTE" },
        { id: 13, name: "ATLAS" },
        { id: 14, name: "CHUBB" },
        { id: 15, name: "GMX" },
        { id: 16, name: "ALLIANZ" },
        { id: 17, name: "ARGOS" },
        { id: 18, name: "ASERTA" },
        { id: 19, name: "BX+" },
        { id: 20, name: "MUTUUS" },
        { id: 21, name: "PLAN SEGURO" },
        { id: 22, name: "PREVEM" },
        { id: 23, name: "SISNOVA" },
        { id: 24, name: "BUPA" },
        { id: 25, name: "METLIFE" },
        { id: 26, name: "INSIGNIA" },
        { id: 27, name: "MAPFRE" },
        { id: 28, name: "EL POTOSI" },
        { id: 29, name: "Quálitas" },
        { id: 30, name: "Gnp" },
        { id: 31, name: "AIG Seguros México" },
        { id: 32, name: "Inter.mx" },
        { id: 33, name: "General de Seguros" },
      ],
    };
  },
  methods: {
    getFormatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "Sin llenar";
    },
    getCurrentAseguradora(id){
      let aseguradora = this.aseguradorasList.find((e) => e.id == id)
      return aseguradora ? aseguradora.name : 'No disponible'
    },
    fillData() {
      const jsonData = this.datosSolicitud.solicitudBody || "{}";
      Object.keys(this.formData).forEach((key) => {
        if (jsonData[key] !== undefined) {
          this.formData[key] = jsonData[key];
        }
      });
      this.formData.status = this.datosSolicitud.status ?? null;
      this.formData.empresa = this.datosSolicitud.empresa ?? null;
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
