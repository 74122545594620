<template>
  <div>
    <v-row>
      <v-col cols="12">
        <common-card>
          <v-card-text class="font-montserrat">
            <v-row>
              <v-col cols="5">
                <v-responsive
                  width="100%"
                  class="align-center"
                  height="100%"
                  style="margin: 0 auto;"
                >
                  <p class="subtitulo-header-2 ma-0">
                    Aserguradora
                  </p>
                </v-responsive>
              </v-col>
              <v-col cols="7">
                <v-responsive
                  width="30%"
                  height="100%"
                  style="margin: 0 auto;"
                >
                  <v-img :src="jsonInfo.cotizacionSelected.logo" contain></v-img>
                </v-responsive>
              </v-col>
              <v-col cols="5">
                <v-responsive
                  width="100%"
                  class="align-center"
                  height="100%"
                  style="margin: 0 auto;"
                >
                  <p class="subtitulo-header-2 ma-0">
                    Plan
                  </p>
                </v-responsive>
              </v-col>
              <v-col cols="7">
                <v-responsive
                  width="30%"
                  height="100%"
                  style="margin: 0 auto;"
                >
                <p class="font-montserrat">
                  {{getTipoPlanName(jsonInfo.cotizacionSelected.plan)}}
                  </p>
                </v-responsive>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>
      <v-col cols="12">
        <common-card>
          <v-card-text>
            <div>
              <span class="subtitulo-header-2 font-montserrat">
                Vehiculo
              </span>
            </div>
            <v-row class="pt-4">
              <v-col cols="5" class="whitout-padding-bottom">
                <span class="font-montserrat">
                  Marca/Modelo
                </span>
              </v-col>
              <v-col cols="7" class="whitout-padding-bottom">
                <span class="font-montserrat font-weight-bold">
                  {{ getAutoFullName() }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <span class="font-montserrat">
                  Año
                </span>
              </v-col>
              <v-col cols="7">
                <span class="font-montserrat font-weight-bold">
                  {{ jsonInfo.carData.model }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>
      <v-col cols="12">
        <common-card>
          <v-card-text>
            <div class="whitout-padding-bottom">
              <span class="subtitulo-header-2 font-montserrat">
                Conductor
              </span>
            </div>
            <v-row class="pt-4">
              <v-col cols="5" class="whitout-padding-bottom">
                <span class="font-montserrat">
                  Nombre
                </span>
              </v-col>
              <v-col cols="7" class="whitout-padding-bottom">
                <span class="font-montserrat font-weight-bold">
                  {{ jsonInfo.contractorData.firstName }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="whitout-padding-bottom">
                <span class="font-montserrat">
                  Sexo de nacimiento
                </span>
              </v-col>
              <v-col cols="7" class="whitout-padding-bottom">
                <span class="font-montserrat font-weight-bold">
                  {{ getGender(jsonInfo.contractorData.gender) }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="whitout-padding-bottom">
                <span class="font-montserrat">
                  Fecha de nacimiento
                </span>
              </v-col>
              <v-col cols="7" class="whitout-padding-bottom">
                <span class="font-montserrat font-weight-bold">
                  {{ getFormatDate(jsonInfo.contractorData.birthDate) }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="whitout-padding-bottom">
                <span class="font-montserrat">
                  Código Postal
                </span>
              </v-col>
              <v-col cols="7" class="whitout-padding-bottom">
                <span class="font-montserrat font-weight-bold">
                  {{ jsonInfo.contractorData.cp }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import moment from "moment";

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      tiposPlan: [
        {
          id: "AMP",
          nombre: "AMPLIA",
        },
        {
          id: "RC",
          nombre: "RESPONSABILIDAD CIVIL",
        },
        {
          id: "LIMIT",
          nombre: "LIMITADA",
        },
      ],
    };
  },
  methods: {
    toCurrency(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number); /* $2,500.00 */
    },
    getTipoPlanName(id){
      return this.tiposPlan.find(
        (tipoPlan) => tipoPlan.id == id
      ).nombre;
    },
    getAutoFullName(){
      return `${this.jsonInfo.carData.brand} ${this.jsonInfo.carData.description}`
    },
    getGender(val){
      return val == "M" ? "Masculino" : "Femenino"
    },
    getFormatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    jsonInfo() {
      return this.$route.query.jsonInfo
        ? JSON.parse(this.$route.query.jsonInfo)
        : null;
    },
  },
};
</script>

<style>

.whitout-padding-bottom{
  padding-bottom: 0;
  padding-top: 0;
}
</style>