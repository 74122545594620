import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../mainService";

export const createSolicitud = async (request) => {
  try {
    const response = await mainAxios.post(
      `v1/solicitud/insert/`,
      request,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const getSolicitudById = async (id) => {
  try {
    const response = await mainAxios.get(
      `v1/solicitud/id/${id}`,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const postReadCorreo = async (body) => {
  try {
    const response = await mainAxios.post(
      `v1/secretManager/read/email`,
        body,  
        getDefaultHeaders()  
    );
    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};


export const getSolicitudesFiltrosAreaNegocio = async (filters, elementosPagina, pagina, areaNegocioId) => {
  try {
    const response = await mainAxios.get(
      `v1/solicitudes/filters/area_negocio`, 
      {
        ...getDefaultHeaders(),
        params: {
          filters: JSON.stringify(filters), 
          limit:elementosPagina,
          page: pagina,
          areaNegocioId: areaNegocioId
        },
        
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const putUpdateSolicitudesByuuid = async (uuid, request) => {
  try {
    const response = await mainAxios.put(
      `/v1/solicitud/update/${uuid}`, 
      request,
      getDefaultHeaders()
    );
    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

