import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";
import { getDefaultHeaders, getErrorMessage } from "../mainService";


export const getUsersList = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.post(
    "/v1/agente/list-filtro",
    {
      filtroBusqueda:'',
    },
    config
  );
  return serverResponse.data

};

export const getAgentesByFilters = async (filters, elementosPagina, pagina) => {
  try {
    const response = await mainAxios.get(
      `v1/agente/filters/area_negocio`, 
      {
        ...getDefaultHeaders(),
        params: {
          filters: JSON.stringify(filters), 
          limit:elementosPagina,
          page: pagina
        },
        
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
