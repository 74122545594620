<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos de contacto
      </template>
      <v-expansion-panel-content>
        <ViewContactClienteSolcitudInfo v-if="switch_data == 1" :datosSolicitud="datosSolicitud"
          @changeComponent="switch_data = 2" :rol="rol" />
        <EditContactClienteSolcitudInfo v-else :datosSolicitud="datosSolicitud" @changeComponent="switch_data = 1"
          @getData="$emit('getData'), (switch_data = 1)" />
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewContactClienteSolcitudInfo from "./ViewContactClienteSolcitudInfo.vue";
import EditContactClienteSolcitudInfo from "./EditContactClienteSolcitudInfo.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewContactClienteSolcitudInfo,
    EditContactClienteSolcitudInfo,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0]?.rol || ""
    };
  },
  props: {
    datosSolicitud: Object,
  },
  computed: {
    //se valida si el usuario puede editar la solicitud , si es adminagent no puede editar por regla de negocio
    canEdit() {
      return this.rol !== 'ADMINAGENT';
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}

.v-expansion-panels {
  z-index: auto !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
