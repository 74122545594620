export const dominiosSegurify = ["segurify.com", "inter.mx", "interify.mx", "intertkm.com.mx", "pentafon.com"];

export const validarDominioSegurify = (correo) => {
  if (!correo) return true;
  const dominio = correo.split("@")[1];

  if (!dominiosSegurify.find((d) => d === dominio))
    return "Dominio no permitido";

  return true;
};

export default validarDominioSegurify;
