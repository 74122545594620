<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.codigoPostal"
              label="Código Postal"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.calle"
              label="Calle"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.numExterior"
              label="Numero Exterior"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.numInterior"
              label="Numero interior"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Colonia"
              v-model="formData.colonia"
              :items="colonias"
              item-text="nombreColonia"
              item-value="nombreColonia"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Ciudad"
              v-model="formData.municipio"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
              readonly
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Estado de la República"
              v-model="formData.estadoRepublicaSelected"
              placeholder="Requerido"
              :items="estados"
              item-text="name"
              item-value="id"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import { mainAxios } from "@/mainAxios.js";
export default {
  components: {
    CommondForm,
  },

  data() {
    return {
      switch_data: 1,
      formData: {
        codigoPostal: null,
        estadoRepublicaSelected: null,
        calle: null,
        numExterior: null,
        numInterior: null,
        colonia: null,
        municipio: null,
      },
      run: false,
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      colonias: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
    };
  },
  props: {
    datosSolicitud: Object,
  },
  watch: {
    "formData.codigoPostal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.colonias = response.data.listaColonias;
                this.formData.estadoRepublicaSelected = Number(response.data.nEstado);
                this.formData.municipio = response.data.nombreMunicipio;
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    },
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.formData.codigoPostal = this.datosSolicitud.codigoPostal ?? null;
      this.formData.estadoRepublicaSelected =
      this.datosSolicitud.estadoRepublica ?? null;
      var jsonData = JSON.parse(this.datosSolicitud.body);
      this.formData.calle = jsonData.calle ? jsonData.calle : null;
      this.formData.numExterior = jsonData.numExterior ?? null;
      this.formData.numInterior = jsonData.numInterior ?? null;
      this.formData.colonia = jsonData.colonia ?? null;
      this.formData.municipio = jsonData.municipio ?? null;
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
