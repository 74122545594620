<template>
  <v-dialog v-model="OpenDialogCreateEmail" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="2">
                <v-img :src="emailImg" max-width="40" he alt=""> </v-img>
              </v-col>
              <v-col cols="10">                
                <span>
                  Crear correo electrónico
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="closeModal"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <commond-form
            :request="formData"
            :route="emailRoutesConfig.sendEmailRoute"
            :run="run"
            :action="emailRoutesConfig.action"
            @cancel="run = false"
            @success="closeComponent"
            @afterError="closeComponent"
          >
            <template v-slot:data>
              <v-row class="pt-5">
                <v-row class="pl-4">
                  <v-col cols="10" >
                    <v-select
                      label="Desde:"
                      outlined
                      v-model="data.remitente"
                      :items="optionsFrom"
                      color="#00a7e4"
                      type="text"
                      item-text="cuenta"
                      item-value="cuenta"
                      :rules="requiredRule"
                      chips
                    ></v-select>
                  </v-col>
                  <v-col cols="10">
                    <v-select
                      label="Para:"
                      outlined
                      v-model="data.to"
                      :items="optionsEmails"
                      color="#00a7e4"
                      type="text"
                      item-text="correo"
                      item-value="correo"
                      :rules="requiredRule"
                      multiple
                      chips
                      deletable-chips
                      select-all
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <div>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="to = 'to' ;openDialog = true"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col cols="10">
                    <v-select
                      label="CC:"
                      outlined
                      v-model="data.cc"
                      :items="optionsEmails"
                      color="#00a7e4"
                      type="text"
                      item-text="correo"
                      item-value="correo"
                      multiple
                      chips
                      deletable-chips
                      select-all
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <div>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="to = 'cc'; openDialog = true"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-select
                      label="BCC:"
                      outlined
                      v-model="data.bcc"
                      :items="optionsEmails"
                      color="#00a7e4"
                      type="text"
                      item-text="correo"
                      item-value="correo"
                      multiple
                      chips
                      deletable-chips
                      select-all
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <div>
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="primary"
                        @click="to = 'bcc'; openDialog = true"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-col cols="12">
                    <v-autocomplete
                      label="Plantilla"
                      outlined
                      :loading="isLoading"
                      v-model="plantilla"
                      :items="plantillas"
                      color="#00a7e4"
                      type="text"
                      item-text="nombre"
                      return-object
                      :search-input.sync="busqueda"
                      @change="cambiarPlantilla"
                    >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Escribe para buscar una
                          <strong>plantilla</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Asunto"
                    outlined
                    v-model="data.subject"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-col cols="12">
                    <ckeditor v-model="data.content" :config="editorConfig" />
                  </v-col>
                </v-col>
                <v-col cols="12" class="mt-15">
                  <v-file-input
                    v-model="data.files"
                    color="#00a7e4"
                    counter
                    label="Archivo"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    :rules="requiredRule"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" color="#00a7e4" dark label small>
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ data.files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </template>
          </commond-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" style="text-align: end;">
            <v-btn rounded @click="closeComponent" outlined color="#00a7e4">
              <span>
                Cancelar
              </span>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn rounded dark class="common-botton" @click="afterSend">
              <span>
                Enviar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <new-email
      :openDialog="openDialog"
      @closeDialog="openDialog = false"
      @addEmail="addEmailMethod"
    >
    </new-email>
  </v-dialog>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import NewEmail from "@/components/commonComponents/dialogs/NewEmail.vue";
import emailImg from "@/assets/email.svg";
import { mainAxios } from "@/mainAxios";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ckeditor from "ckeditor4-vue";
export default {
  components: {
    CommondForm,
    NewEmail,
    ckeditor: ckeditor.component,
  },
  props: {
    OpenDialogCreateEmail: {
      type: Boolean,
      default: false,
    },
    emailRoutesConfig: Object,
  },
  data() {
    return {
      editorContent: "<p>Escribe aquí...</p>", // Contenido inicial
      editorConfig: {
        versionCheck: false, // Desactiva el mensaje de actualización
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      to: '',
      plantilla: null,
      plantillas: [],
      data: {
        content: "",
        remitente: "",
        to: [],
        subject: "",
        files: [],
        bcc: [],
        cc: [],
      },
      optionsEmails: [],
      optionsFrom: [],
      openDialog: false,
      formData: {},
      run: false,
      emailImg: emailImg,
      editorOptions: {
        theme: "snow",
        placeholder: "Mensaje",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      isLoading:false,
      busqueda:''
    };
  },
  computed: {
    allEmails() {
      return (
        this.data.to.length === this.emailRoutesConfig.optionCorreosList.length
      );
    },
  },
  watch: {
    OpenDialogCreateEmail(val) {
      if (val && Array.isArray(this.emailRoutesConfig.optionCorreosList)) {
        this.optionsEmails = [...this.emailRoutesConfig.optionCorreosList];
      }
    },
    busqueda(newVal) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        if (newVal) {
          this.isLoading = true;
          this.getPlantillas();
        }else{
          this.plantillas = [];
          this.isLoading = false;
          this.plantilla = ''
        }
      }, 1000);
    }
  },
  methods: {
    cambiarPlantilla() {
      if (this.plantilla) {
        this.data.subject = this.plantilla.asunto;
        this.getPlantilla(this.plantilla.id);
      }
    },
    getPlantilla(id) {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/plantillas-eci/${id}`, config)
        .then((response) => {
          this.data.content = response.data.data.detalle;
        });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    afterSend() {
      this.formData = new FormData();
      for (var m = 0; m < this.data.files.length; m++) {
        let file = this.data.files[m];
        this.formData.append("files", file);
      }
      this.formData.append("remitente", this.data.remitente);
      this.formData.append("to", this.data.to);
      this.formData.append("content", this.data.content);
      this.formData.append("subject", this.data.subject);
      this.formData.append("bcc", this.data.bcc);
      this.formData.append("cc", this.data.cc);
      if (this.emailRoutesConfig.solicitud)
        this.formData.append("solicitud", `${this.emailRoutesConfig.solicitud}`);
      if (this.emailRoutesConfig.cuota)
        this.formData.append("cuota", `${this.emailRoutesConfig.cuota}`);
      else if (this.emailRoutesConfig.poliza) this.formData.append("emision", `${this.emailRoutesConfig.poliza}`);
      else if (this.emailRoutesConfig.cuota) this.formData.append("cuota", `${this.emailRoutesConfig.cuota}`);
      else if (this.emailRoutesConfig.cliente) this.formData.append("cliente", `${this.emailRoutesConfig.cliente}`);
      else if (this.emailRoutesConfig.usuario)
        this.formData.append("agente_id", `${this.emailRoutesConfig.usuario}`);
      else if (this.emailRoutesConfig.prospectoId)
        this.formData.append("prospecto_id", `${this.emailRoutesConfig.prospectoId}`);
      this.run = true;
    },
    closeComponent() {
      this.data = Object.assign({
        content: "",
        to: [],
        subject: "",
        files: [],
        bcc: [],
        cc: [],
      });
      this.plantilla = null;
      this.$emit("success");
      this.$emit("closeModal");
    },
    addEmailMethod(email) {
      this.openDialog = false;
      this.optionsEmails.push({
        correo: email,
      });
      this.data[this.to].push(email);
      this.$toast.success("Correo agregado con exito ! ", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    getPlantillas(){
      const filters = `?areas=[${localStorage.getItem("area_negocio_id")}]&nombre=${this.busqueda}`;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/plantillas-eci/${filters}`, config).then((response) => {
        this.plantillas = response.data.data.plantillas;
        this.isLoading = false 
      }).catch(err => {
        console.log(err)
        this.isLoading = false 
      });
    }
  },
  created() {
    let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
    mainAxios
      .get(`/v1/cuentasCorreo/?areaNegocioId=${localStorage.getItem("area_negocio_id")}`, config)
      .then((response) => {
        console.log(response.data);
        this.optionsFrom = response.data.cuentas;
        if(this.optionsFrom.length < 1){
          this.optionsFrom.push('operaciones@interify.mx')
          this.data.remitente = this.optionsFrom[0]
        }
      });
  },
};
</script>