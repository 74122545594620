<template>
  <common-card class="mt-3">
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px;">
          <v-col cols="12" md="6">
            <h1>Solicitudes Emails</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels
      elevation="0"
      style="padding: 30px; border-radius: 20px"
    >
      <v-expansion-panel>
        <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            Búsqueda detallada
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                v-model="filtros.cuenta"
                label="Cuenta correo"
                :items="cuentasCorreo"
                item-text="cuenta"
                item-value="id"
                outlined
                multiple
                color="primary" 
                background-color="white"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="selectAllCuentas"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        Seleccionar Todas
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <InputPeriodo
                @updateDate="updateDate"
                :bgColor="'white'"
                :inicialDates="false"
                :cleanDates="cleanDates"
                @input="validateDateRange"
                style="min-width: 50%;"
              ></InputPeriodo>
              <v-alert
                v-if="showDateRangeError"
                type="error"
                dense
                text
                class="mt-2"
              >
                El rango de fechas no puede ser mayor a 1 mes
              </v-alert>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="filtros.status"
                label="Estatus Solicitud"
                :items="status"
                item-text="label"
                item-value="value"
                outlined
                multiple
                color="primary"
                background-color="white"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="selectAllStatus"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        Seleccionar Todos
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="filtros.ejecutivo"
                label="Ejecutivo"
                clearable
                :items="ejecutivos"
                item-text="nombreCompleto"
                item-value="id"
                outlined
                color="primary"
                background-color="white"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row style="padding: 0px 40px 0px 40px;" class="d-flex justify-end">
            <v-col cols="12" md="2">
              <v-btn
              dark
              block
              outlined
              color="#00a7e4"
              rounded
              @click="clearFilters()"
              >
              Limpiar
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" style="text-align: end;">
            <v-btn
              class="common-botton"
              dark
              block
              :loading="loadingReport"
              :disabled="!isValidFilter"
              @click="getReport"
            >
              <span>Filtrar</span>
            </v-btn>
          </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <v-row v-if="canDownloadReport">
      <v-col cols="12" style="text-align: end; padding: 40px 40px 0px 40px;">
        <v-btn
          class="common-botton"
          dark
          :loading="loadingDownload"
          :disabled="!hasRecords"
          @click="downloadFile"
        >
          <span>Descargar reporte</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mb-10" style="padding: 40px">
        <v-data-table
          style=""
          :headers="tableHeaders"
          @pagination="pagination"
          :server-items-length="totalItems"
          :items="templates"
          item-key="id"
          class="elevation-0"
          :loading="loadingReport"
          loading-text="Cargando reporte..."
          no-data-text="Para ver elementos, primero realiza una búsqueda"
        >
          <template v-slot:top>
            <v-row style="padding: 15px;">
              <v-col cols="8">
                <h2>Resultados de la búsqueda</h2>
              </v-col>
            </v-row>
          </template>
          <template #item.fecha_creacion="{item}">
            <span>{{ moment(item.fechaRegistro).format("DD/MM/YYYY") }}</span>
          </template>

          <template #item.cliente="{item}">
            <span>{{ item.cliente || 'Sin Informacion' }}</span>
          </template>
          <template #item.tipificacion="{item}">
            <span>{{ item.tipificacion || 'Sin Informacion' }}</span>
          </template>
          <template #item.ejecutivo="{item}">
            <span>{{ item.ejecutivo || 'Sin Informacion' }}</span>
          </template>

          <template v-slot:item.ver="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="verItem(item)"
              v-if="item.statusId != 3"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </common-card>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import InputPeriodo from "@/components/commonComponents/forms/InputPeriodo.vue";
import { getCuentasCorreoListByParams } from "@/services/cuentasCorreo/cuentasCorreo.service";
import download from "downloadjs";

export default {
  components: {
    CommonCard,
    InputPeriodo
  },
  data() {
    return {
      moment,
      templates: [],
      totalItems: 0,
      roles: JSON.parse(localStorage.getItem("roles")),
      status: [
        { label: "Nueva", value: "0" },
        { label: "Asignada", value: "1" },
        { label: "En tramite", value: "2" },
        { label: "Pendiente de Aprobacion", value: "3" },
        { label: "Pendiente de Informacion", value: "4" },
        { label: "Recotizar", value: "5" },
        { label: "Cerrada", value: "6" },
        { label: "Cancelada", value: "7" },
      ],
      loading: false,
      loadingReport: false,
      loadingDownload: false,
      search: "",
      filtros: {
        cuenta: [],
        status: [],
        ejecutivo: "",
        fechaInicio: "",
        fechaFin: "",
        pagina: 1,
        elementosPagina: 10,
      },
      tableHeaders: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id_solicitud",
        },
        { text: "Titulo", value: "asunto" },
        { text: "Estatus", value: "estatus" },
        { text: "Fecha Registro", value: "fecha_creacion" },
        { text: "Cliente", value: "cliente" },
        { text: "Tipo", value: "tipificacion" },
        { text: "Ejecutivo", value: "ejecutivo" },
        { text: "Ver", value: "ver" },
      ],
      report: [],
      cuentasCorreo: [],
      ejecutivos: [],
      showDateRangeError: false,
    };
  },

  watch: {
  },

  computed: {
    canDownloadReport() {
      let canView = false;
      let roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [ "ADMIN","OPERACIONESEMAILS","MANAGEREMAILS"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    isValidFilter() {
      return this.filtros.cuenta && this.filtros.fechaInicio && this.filtros.fechaFin;
    },
    hasRecords() {
      return this.templates && this.templates.length > 0;
    }
  },

  methods: {
    async downloadFile() {
      try {
        this.loadingDownload = true;
        const config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let body = {
          cuentas: this.filtros.cuenta,
          status: this.filtros.status,
          ejecutivo: this.filtros.ejecutivo,
          fechaInicio: this.filtros.fechaInicio,
          fechaFin: this.filtros.fechaFin,
          pagina: this.filtros.pagina,
          elementosPagina: this.filtros.elementosPagina,
        };

        const serverResponse = await mainAxios.post(
          "/v1/reportes/solicitudes-email/file",
          body,
          config
        );

        if (serverResponse) {
          download(serverResponse.data, `Reporte_Solicitudes_Email.csv`, "application/csv");
        }
      } catch (error) {
        console.error('Error al descargar el reporte:', error);
      } finally {
        this.loadingDownload = false;
      }
    },
    updateDate(date) {
      if (this.validateDateRange(date)) {
        this.filtros.fechaInicio = date[0];
        this.filtros.fechaFin = date[1];
        this.showDateRangeError = false;
      } else {
        this.filtros.fechaInicio = '';
        this.filtros.fechaFin = '';
        this.showDateRangeError = true;
      }
    },
    validateDateRange(dates) {
      if (!dates[0] || !dates[1]) return true;
      
      const inicio = moment(dates[0]);
      const fin = moment(dates[1]);
      const diffInDays = fin.diff(inicio, 'days');
      
      return diffInDays <= 31;
    },
    clearFilters() {
      this.filtros = {
        cuenta: [],
        status: [],
        ejecutivo: "",
        fechaInicio: "",
        fechaFin: "",
        pagina: 1,
        elementosPagina: 10,
      };
    },
    getAgentes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agente/list", requestConfig).then(({ data }) => {
        this.ejecutivos = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },
    async getCatalogs() {
      this.loading = true;
      const cuentasCorreo = await getCuentasCorreoListByParams({
        areaNegocioId: localStorage.area_negocio_id,
      });
      this.cuentasCorreo = cuentasCorreo.cuentas;
      this.getAgentes();
      this.loading = false;


    },
    pagination(event) {
      this.filtros.pagina = event.page;
      this.filtros.elementosPagina = event.itemsPerPage;
      this.getReport();
    },
    async getReport() {
      try {
        this.loadingReport = true;
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        let body = {
          cuentas: this.filtros.cuenta,
          status: this.filtros.status,
          ejecutivo: this.filtros.ejecutivo,
          fechaInicio: this.filtros.fechaInicio,
          fechaFin: this.filtros.fechaFin,
          pagina: this.filtros.pagina,
          elementosPagina: this.filtros.elementosPagina,
        };
        
        const response = await mainAxios.post(`/v1/reportes/solicitudes-email/`, body, config);
        this.totalItems = response.data.data.count;
        this.templates = response.data.data.solicitudesEmail;
      } catch (error) {
        console.error('Error al obtener el reporte:', error);
      } finally {
        this.loadingReport = false;
      }
    },
    verItem(item) {
      this.$router.push(`/detalles-solicitud/${item.id_solicitud}`);
    },

    transformToParamsString(params) {
      let paramsString = "?";
      Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          paramsString +=
            params[key].length > 0 ? `${key}=[${params[key]}]&` : "";
        } else {
          paramsString +=
            params[key] !== null &&
            params[key] !== undefined &&
            params[key] !== ""
              ? `${key}=${params[key]}&`
              : "";
        }
      });
      paramsString = paramsString.slice(0, -1);
      paramsString = encodeURI(paramsString);
      paramsString = paramsString.replace(/#/g, "%23");
      paramsString = paramsString.replace(/\[/g, "%5B");
      paramsString = paramsString.replace(/\]/g, "%5D");
      return paramsString;
    },
    transformIdtoItem(id, array) {
      let selected = array.find((element) => element.id == id);
      return selected ?? { etiqueta: "-" };
    },
    selectAllCuentas() {
      if (this.filtros.cuenta.length === this.cuentasCorreo.length) {
        this.filtros.cuenta = [];
      } else {
        this.filtros.cuenta = this.cuentasCorreo.map(cuenta => cuenta.id);
      }
    },
    selectAllStatus() {
      if (this.filtros.status.length === this.status.length) {
        this.filtros.status = [];
      } else {
        this.filtros.status = this.status.map(status => status.value);
      }
    },
  },
  mounted() {
    this.getCatalogs();
  },
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}

.v-alert {
  margin-bottom: 0;
  font-size: 12px;
}
</style>
