<template>
  <div>
    <div>
      <v-row class="pt-5 pb-5">
        <v-col cols="10">
          <h2>
            Detalle de la solicitud / {{ solicitud.titulo }}
          </h2>
        </v-col>
        <v-col cols="2" style=" text-align: end;">
          <h2>#{{ uuid }}</h2>
        </v-col>
      </v-row>
    </div>
    <info-card-solicitudes
      :infoCards="infoCards"
      :emailRoutesConfig="emailRoutesConfig"
      @getData="updateView"
    >
    </info-card-solicitudes>
    <IndexAreaDeNegocio/>
    <div v-if="tabReplace == 4">
      <div style="padding-top: 60px;">
        <BeneficiariosIndex
          :beneficiarios="beneficiarios"
          @getBeneficiarios="getBeneficiariosBySolicitudId"
          @closeModal="closeModalBeneficiario"
        ></BeneficiariosIndex>
      </div>
    </div>
    <CreateBeneficiarioModal
      :solicitud_id="String(solicitud.id)"
      :dialog="dialogBeneficiarios"
      :currentPorcentaje="currentPorcentaje"
      @closeModal="closeModalBeneficiario"
      @getBeneficiarios="closeModalBeneficiario"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mainAxios } from "@/mainAxios.js";
import InfoCardSolicitudes from "./InfoCardSolicitudes.vue";
import { getClienteById } from "@/services/cliente/Clientes.js";
import IndexAreaDeNegocio from "./EmisionDatosGenerales/FlujoEmisionesPorAreaNegocio/IndexAreaDeNegocio.vue"
import CreateBeneficiarioModal from "@/components/commonComponents/dialogs/CreateBeneficiarioModal.vue";
import { getBeneficiariosBySolicitudId } from "@/services/beneficiarios/benefiario.service";
import { getProductoList } from "@/services/productos/product.service";
import BeneficiariosIndex from "./Beneficiarios/BeneficiariosIndex.vue";
    
export default {
  components: {
    InfoCardSolicitudes,
    //LogSolicitudes,
    CreateBeneficiarioModal,
    BeneficiariosIndex,
    IndexAreaDeNegocio
  },
  data() {
    return {
      tabReplace: 1,
      documentsDialog: false,
      newCorreoDialog: false,
      dialogCorreo: false,
      accionGuardar: false,
      correoOpen: {},
      dialogDetalleDocumentos: false,
      detallesDocumento: {},
      expanded: [],
      hidden: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      solicitud: [],
      correos: [],
      documentos: [],
      columns: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
      tipoItems: ["Nota", "Documento"],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Contrato firmado del Agente",
        "Cotización",
        "Cédula del Agente",
        "Identificación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      uuid: this.$route.params.id,

      //SLA
      fechaNueva: "",
      horaNueva: "",

      fechaInfoCompleta: "",
      horaInfoCompleta: "",

      fechaPendienteInfo: "",
      horaPendienteInfo: "",

      fechaEnTramite: "",
      horaEnTramite: "",

      fechaCotizacionTramitada: "",
      horaCotizacionTramitada: "",

      fechaRecotizacion: "",
      horaRecotizacion: "",

      fechaEmisionEnProceso: "",
      horaEmisionEnProceso: "",

      fechaFinalizacion: "",
      horaFinalizacion: "",

      fechaCancelacion: "",
      horaCancelacion: "",
      creadaInbounds: false,
      todosAgentes: [],
      razonSocial: [],
      datosSolicitud: {},
      infoCards: {
        notas: [],
        docs: [],
        emails: [],
        correosSegurify: []
      },
      routesConfigNoteCard: {
        route: "",
        action: "",
      },
      emailRoutesConfig: {
        sendEmailRoute: "/v1/portal/correo/enviar",
        action: "POST",
        optionCorreosList: [],
        destinatario: "",
      },
      operadores: {},
      telefonosCliente: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      dialogBeneficiarios: false,
      beneficiarios: [],
      productHasBeneficiario: false,
      correosSegurify: []
    };
  },
  methods: {
    verDetalleCorreo(correo) {
      this.dialogCorreo = true;
      this.correoOpen = correo;
    },
    verDocumento(documento) {
      this.dialogDetalleDocumentos = true;
      this.detallesDocumento = documento;
    },
    goBack() {
      return this.$router.go(-1);
    },
    onScroll() {
      this.scrollInvoked++;
    },
    mostrarInputs() {
      if (
        this.tipoSelected == "Cotización" ||
        this.tipoSelected == "Emisión" ||
        this.tipoSelected == "Renovación"
      ) {
        return true;
      } else return false;
    },
    // ocultarBotonEditar() {
    //   if (this.estadoSelected == 'Cerrada' || this.estadoSelected == 'Cancelada') {
    //     this.hidden = true
    //   }
    // },
    obtnerDatosSolicitud() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/solicitud/id/${this.uuid}`, config)
        .then((response) => {
          console.log({response})
          this.solicitud = [];
          this.correos = [];
          this.documentos = [];
          this.solicitud = response.data;
          this.bodySolicitud = response.data.body
            ? JSON.parse(response.data.body)
            : "";
          response.data.correos
            ? response.data.correos.map((element) => {
                this.correos.push({
                  asunto: element.subject,
                  fecha: element.fecha
                    ? moment
                        .utc(element.fecha)
                        .subtract(6, "hours")
                        .format("DD/MM/YYYY HH:mm")
                    : null,
                  cuerpo: element.cuerpo,
                  remitente: element.remitente,
                  documentos: element.documentos,
                  to: element.to,
                  cc: element.cc,
                  bcc: element.bcc,
                });
              })
            : "";
          response.data.logs
            ? response.data.logs.map((element) => {
                // console.log("Logs: ", element)
                switch (element.status) {
                  case "Nueva":
                    this.fechaNueva = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaNueva = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "Asignada":
                    this.fechaInfoCompleta = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaInfoCompleta = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "Pendiente de Informacion":
                    this.fechaPendienteInfo = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaPendienteInfo = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "En tramite/Cotizacion":
                    this.fechaEnTramite = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaEnTramite = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "Pendiente de Aprobacion":
                    this.fechaCotizacionTramitada = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaCotizacionTramitada = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "Recotizacion":
                    this.fechaRecotizacion = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaRecotizacion = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "En tramite/Emision":
                    this.fechaEmisionEnProceso = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaEmisionEnProceso = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "Cerrada":
                    this.fechaFinalizacion = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaFinalizacion = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  case "Cancelada":
                    this.fechaCancelacion = moment(
                      element.fechaCambio.slice(0, 10),
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY");
                    this.horaCancelacion = moment
                      .utc(element.fechaCambio)
                      .subtract(6, "hours")
                      .format("HH:mm:ss");
                    break;
                  default:
                    break;
                }
              })
            : "";

          var jsonData = JSON.parse(response.data.body);
          this.datosSolicitud = {
            id: response.data.id,
            origen: response.data.origen ? response.data.origen : "",
            status: response.data.status ? response.data.status : "",
            tipo: response.data.tipo ? response.data.tipo : "",
            categoria: response.data.categoria ? response.data.categoria : "",
            nombreAgente: response.data.nombreAgente
              ? response.data.nombreAgente
              : "",
            correoAgente: response.data.correoAgente
              ? response.data.correoAgente
              : "",
            productoId: response.data.productoId
              ? response.data.productoId
              : "",
            nombreEjecutivo: response.data.nombreEjecutivo
              ? response.data.nombreEjecutivo
              : "",
            seguimientoSolicitud: this.bodySolicitud.seguimientoSolicitud
              ? this.bodySolicitud.seguimientoSolicitud
              : "",
            oportunidades: this.bodySolicitud.oportunidades
              ? this.bodySolicitud.oportunidades
              : "",
            nombres: response.data.nombre ? response.data.nombre : "",
            apellidoPaterno: response.data.apellidoPaterno
              ? response.data.apellidoPaterno
              : "",
            apellidoMaterno: response.data.apellidoMaterno
              ? response.data.apellidoMaterno
              : "",
            fechaNacimiento: response.data.fechaNacimiento
              ? moment(response.data.fechaNacimiento).format("DD/MM/YYYY")
              : "",
            sexo: response.data.sexo ? response.data.sexo : "",
            modelo: this.bodySolicitud.modelo ? this.bodySolicitud.modelo : "",
            marca: this.bodySolicitud.marca ? this.bodySolicitud.marca : "",
            submarca: this.bodySolicitud.submarca
              ? this.bodySolicitud.submarca
              : "",
            detalle: this.bodySolicitud.detalle
              ? this.bodySolicitud.detalle
              : "",
            version: this.bodySolicitud.version
              ? this.bodySolicitud.version
              : "",
            inicioVigencia: response.data.fechaInicioVigencia
              ? moment(response.data.fechaInicioVigencia).format("DD/MM/YYYY")
              : "",
            finVigencia: response.data.fechaFinVigencia
              ? moment(response.data.fechaFinVigencia).format("DD/MM/YYYY")
              : "",
            cobertura: this.bodySolicitud.cobertura
              ? this.bodySolicitud.cobertura
              : "",
            celular: response.data.celular ? response.data.celular : "",
            correoElectronico: response.data.correoElectronico
              ? response.data.correoElectronico
              : "",
            edad: this.bodySolicitud.edad ? this.bodySolicitud.edad : "",
            direccion: this.bodySolicitud.direccion
              ? this.bodySolicitud.direccion
              : "",
            coberturasSelected: this.bodySolicitud.coberturasSelected
              ? this.bodySolicitud.coberturasSelected
              : "",
            formaPagoSelected: response.data.formaDePago
              ? response.data.formaDePago
              : "",
            estadoRepublicaSelected: response.data.estadoRepublica
              ? response.data.estadoRepublica
              : "",
            estadoCivilSelected: response.data.estadoCivil
              ? response.data.estadoCivil
              : "",
            codigoPostal: response.data.codigoPostal
              ? response.data.codigoPostal
              : "",
            solicitudCompletaSelected: response.data.solicitudCompleta
              ? "Si"
              : "No",
            rfc: response.data.rfc ? response.data.rfc : "",
            regimenPersona: this.bodySolicitud.regimen_persona
              ? this.bodySolicitud.regimen_persona
              : null,
            procesoCotizacion: response.data.procesoCotizacion
              ? response.data.procesoCotizacion
              : "Cotización",
            polizaRelacionada: response.data.emisionId
              ? response.data.emisionId
              : null,
            valorOportunidad: response.data.valorOportunidad
              ? response.data.valorOportunidad
              : null,
            periodicidadSelected:
              response.data.periodicidadPago == 1
                ? "Anual"
                : response.data.periodicidadPago == 2
                ? "Semestral"
                : response.data.periodicidadPago == 3
                ? "Trimestral"
                : response.data.periodicidadPago == 4
                ? "Mensual"
                : "",
            tipoVehiculoSelected: this.bodySolicitud.tipo_vehiculo
              ? this.bodySolicitud.tipo_vehiculo
              : "",
            usoAutoSelected: this.bodySolicitud.uso_auto
              ? this.bodySolicitud.uso_auto
              : "",
            numMotor: this.bodySolicitud.numMotor
              ? this.bodySolicitud.numMotor
              : "",
            numSerie: this.bodySolicitud.numSerie
              ? this.bodySolicitud.numSerie
              : "",
            placas: this.bodySolicitud.placas ? this.bodySolicitud.placas : "",
            cumpleSla: response.data.slaGeneral,
            creadoPor: response.data.creadoPor
              ? response.data.creadoPor
              : "No Disponible",
            fechaCreacion: response.data.fechaCreacion
              ? moment
                  .utc(response.data.fechaCreacion)
                  .subtract(6, "hours")
                  .format("DD/MM/YYYY HH:mm")
              : "No Disponible",
            modificadoPor: response.data.modificadoPor
              ? response.data.modificadoPor
              : "No Disponible",
            fechaModificacion: response.data.fechaModificacion
              ? moment
                  .utc(response.data.fechaModificacion)
                  .subtract(6, "hours")
                  .format("DD/MM/YYYY HH:mm")
              : "No Disponible",
            solicitudBody: JSON.parse(response.data.body),
            campania: response.data.campania,
            ejecutivo: response.data.operadorId ? response.data.operadorId : "",
            razonSocial: this.bodySolicitud.razon_social
              ? this.bodySolicitud.razon_social
              : null,
            actividadSolicitud: jsonData.actividadSolicitud
              ? jsonData.actividadSolicitud
              : "",
            creadaInbounds: jsonData.esInbound ? jsonData.esInbound : false,
            selectedGenero: jsonData.selectedGenero
              ? jsonData.selectedGenero
              : false,
            estado_civil: jsonData.estado_civil ? jsonData.estado_civil : false,
            numeroPoliza: jsonData.numeroPoliza ? jsonData.numeroPoliza : "",
            agenteId: response.data.agenteId,
            uuid: response.data.uuid,
            comercialAsignado: response.data.comercialAsignado,
            idOrigenLead: jsonData.idOrigenLead,
            anios: jsonData.anios,
            solicitudCompleta: response.data.solicitudCompleta,
            alianzaId: response.data.alianzaId ? response.data.alianzaId : null,
            titulo: response.data.titulo ? response.data.titulo : null,
          };
          this.getBeneficiariosBySolicitudId();
          this.checkIfNeedBeneficiary();
          this.telefonosCliente.push(this.datosSolicitud.celular);
          this.formatTelefonosCliente();
          this.obtenerProductos(response.data.productoId);
          this.formatNotasDocumentos(response.data.documentos);
          this.formatCorreosInfoCard(response.data.correos);
          this.formatCorreosSegurify(response.data.correosSegurify);
          this.calculateRoutesEmails();
        });
      this.permitirEditarAgente();
      this.obtenerProductos();
    },
    obtenerSla() {
      this.fechaInfoCompleta = this.sla[0].fechaCambio;
    },
    verPolizaRelacionada() {
      this.$router.push(`/emision/${this.polizaRelacionada}`);
    },
    formatDate(date) {
      let formattedDate = new Date(
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
      return formattedDate;
    },
    verSolicitudEditable(id) {
      this.$router.push(`/actualizar-solicitud/${id}`);
    },

    agent() {
      var isAgente = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["AGENT"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          isAgente = true;
        }
      });
      this.isAgente = isAgente;
      return isAgente;
    },

    obtenerProductos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        // console.log('Productos: ', response.data);
        response.data.map((element) => {
          // this.productos.push({
          //   nombre: `${element.ramo}`+ ' / ' + `${element.nombre}` + ' / ' + `${element.nombreAseguradora}`,
          //   id: element.id
          // })

          this.productoId == element.id
            ? (this.productoSelected =
                `${element.ramo.toUpperCase()}` +
                " / " +
                `${element.nombre.toUpperCase()}` +
                " / " +
                `${element.nombreAseguradora.toUpperCase()}`)
            : "";
        });
      });
    },
    infoCompleta() {
      if (this.solicitudCompletaSelected == 1) {
        return "Si";
      } else if (this.solicitudCompletaSelected == 0) {
        return "No";
      }
    },
    permitirEditarAgente() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          // console.log("Response agente list: ", element.uuid);
          if (element.uuid == this.agenteUuid) {
            console.log("entró");
          }
        });
      });
    },
    closeSendEmail() {
      this.newCorreoDialog = false;
      this.obtnerDatosSolicitud();
    },

    getAgentes() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/list`, config).then((response) => {
        this.todosAgentes = response.data;
      });
    },
    closeDocumentoDialog() {
      this.dialogDetalleDocumentos = false;
    },
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
    formatNotasDocumentos(docs) {
      this.infoCards.notas = [];
      this.infoCards.docs = [];
      docs.map((e) => {
        console.log("tipo documento: ", e);
        if (e.tipoRegistro == "Nota" || e.tipoRegistro == "nota")
          this.infoCards.notas.push({
            formatData:
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY") +
              " " +
              e.detalle.slice(0, 10) +
              " ... " +
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("HH:mm"),
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            nota: e.detalle,
            ...e,
          });
        else {
          this.infoCards.docs.push({
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            onlyDate: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY"),
            nota: e.detalle,
            url: e.url_bucket,
            ...e,
          });
        }
      });
    },
    calculateRoutesEmails() {
      this.emailRoutesConfig.optionCorreosList.push({
        correo: this.datosSolicitud.correoElectronico,
      });
      this.emailRoutesConfig.remitente = localStorage.agenteMail;
      this.emailRoutesConfig.solicitud = this.$route.params.id;
    },
    formatCorreosInfoCard(correos) {
      this.infoCards.emails = [];
      correos.map((e) => {
        this.infoCards.emails.push({
          showInfo:
            e.subject +
            +" " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY") +
            " " +
            e.subject.slice(0, 10) +
            " ... " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("HH:mm"),
          dateFormat: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm"),
          Onlyfecha: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY"),
          hora: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("HH:mm"),
          url_documento: e.documentos.length > 0 ? e.documentos[0].url : false,
          ...e,
        });
      });
      this.calculateRoutesEmails();
    },
    getOperadores() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agentes/operador", config).then(({ data }) => {
        this.operadores = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },
    updateView() {
      location.reload();
    },
    getAllInfo() {
      this.getOperadores(),
        this.permitirEditarAgente(),
        this.obtnerDatosSolicitud(),
        this.agent(),
        this.infoCompleta(),
        this.mostrarInputs(),
        this.obtenerSla(),
        this.estados;
      this.routesConfigNoteCard = {
        route: `/v1/solicitud/documento/upload/${this.uuid}`,
        action: "POST",
      };
    },
    async formatTelefonosCliente() {
      const response = await getClienteById(
        this.datosSolicitud.solicitudBody.cliente_id
      );
      response.phones.map((e) => {
        this.telefonosCliente.push(e.telefono);
      });
    },
    closeModalBeneficiario() {
      this.dialogBeneficiarios = false;
      this.getBeneficiariosBySolicitudId();
    },
    async getBeneficiariosBySolicitudId() {
      const response = await getBeneficiariosBySolicitudId(
        this.datosSolicitud.id
      );
      this.beneficiarios = response.data.beneficiarios;
    },
    async checkIfNeedBeneficiary() {
      const products = await getProductoList();
      const currentProduct = products.find(
        (e) => e.id == this.datosSolicitud.productoId
      );
      this.productHasBeneficiario = currentProduct
        ? currentProduct.beneficiario
        : false;
    },
    formatCorreosSegurify(correosSegurify){
      this.infoCards.correosSegurify = []
      correosSegurify.forEach(element => {
        let formatText = JSON.parse(element.cuerpo)
        this.infoCards.correosSegurify.push({
          asunto: element.subject,
          dateFormat: element.fechaCreacion ? moment.utc(element.fechaCreacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') : null,
          cuerpo: formatText['text/html'] ? (formatText["text/html"][0] ? formatText["text/html"][0].replace(/\/r|\n/g, "") : formatText["text/plain"] ?? null) : null,
          remitente: element.remitente,
          documentos: element.documentos,
          subject: element.subject,
          tipoCorreo: element.tipoCorreo,
          to: element.to,
          cc: element.cc,
          bcc: element.bcc
        })
      });
    },
  },
  computed: {
    canEdit() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "AGENTCC",
        "AGENTCCSPONSORS",
        "OPERADORCC",
        "ADMINCC",
        "MANAGERCC",
        "MESACONTROLINBOUND",
        "OPERACIONESINBOUND",
        "OPERADORINBOUND",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    ocultarBotonEditar() {
      if (
        this.estadoSelected == "Cerrada" ||
        this.estadoSelected == "Cancelada"
      ) {
        return true;
      }
      return false;
    },
    operadoresInbound() {
      if (this.todosAgentes.length > 0)
        return this.todosAgentes.filter((e) => e.rol == "OPERADORINBOUND");
      else return [];
    },
    infoSLA() {
      return {
        fechaInfoCompleta: this.fechaInfoCompleta,
        horaInfoCompleta: this.horaInfoCompleta,

        fechaPendienteInfo: this.fechaPendienteInfo,
        horaPendienteInfo: this.horaPendienteInfo,

        fechaEnTramite: this.fechaEnTramite,
        horaEnTramite: this.horaEnTramite,

        fechaCotizacionTramitada: this.fechaCotizacionTramitada,
        horaCotizacionTramitada: this.horaCotizacionTramitada,

        fechaRecotizacion: this.fechaRecotizacion,
        horaRecotizacion: this.horaRecotizacion,

        fechaEmisionEnProceso: this.fechaEmisionEnProceso,
        horaEmisionEnProceso: this.horaEmisionEnProceso,

        fechaFinalizacion: this.fechaFinalizacion,
        horaFinalizacion: this.horaFinalizacion,
      };
    },
    currentPorcentaje() {
      let total = 0;
      this.beneficiarios.map((e) => {
        total = Number(e.porcentaje) + Number(total);
      });
      return total;
    },
    calculateColumns() {
      if (this.productHasBeneficiario) {
        return 3;
      }
      return 4;
    },
  },
  mounted() {
    if (this.$route.query.beneficiarios) this.dialogBeneficiarios = true;
    this.getAllInfo();
  },
};
</script>
